import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import './styles.scss';

enum ActionTypes {
  link,
  video_pop_up
};

interface StageProps {
  room_image: string,
  overlays: [{id: number, url: string}],
  language: string,
  country: string
};

const Stage:React.FC<StageProps> = ({
  room_image,
  overlays,
  language,
  country
}) => {
  const roomImages = useStaticQuery(graphql`
    {
      allFile(filter: {sourceInstanceName: {regex: "/roomImages-[a-z]{2}/"}, extension: {in: ["png","jpg"]}}) {
        edges {
          node {
            publicURL
            name
            sourceInstanceName
          }
        }
      },
    }`);
  const roomImage = roomImages.allFile.edges.find((file, index) => file.node.name === room_image && file.node.sourceInstanceName === `roomImages-${country}` );
  return (<div className="stage-wrapper">
    <img className="stage-image" src={roomImage.node.publicURL} />
  </div>);
};

export default Stage;