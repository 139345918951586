import React, {useState, useRef, useCallback, useDebugValue} from 'react';
import {useDrag, useDrop, DndProvider, DragPreviewImage} from 'react-dnd'
import classNames from 'classnames';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Confetti from 'react-confetti';
import './styles.scss';

interface PuzzleProps {
  country: string,
  language: string,
  hidePopUp
};

const Puzzle:React.FC<PuzzleProps> = ({country, hidePopUp, language}) => {
  const [filled, setFilled] = useState(Array(9).fill(false));

  const handleFillPosition = useCallback(index => {
    setFilled(previousFilled => {
      const currentFilled = [...previousFilled];
      currentFilled[index] = true;
      return currentFilled;
    })
  }, [setFilled]);

  const handleReset = useCallback(() => {
    setFilled(Array(9).fill(false));
  }, [setFilled]);

  const handleClose = () => {
    hidePopUp();
  };

  return (<DndProvider backend={HTML5Backend}>
    <div className="jigsaw-wrapper">
      <div className="jigsaw-playmat">
        {[0,1,2].map(rowIndex => [0, 1, 2].map(colIndex => {
              // return (<div key={`position-${(rowIndex * 3) + colIndex}`} className="jigsaw-tile-position">{(rowIndex * 3) + colIndex}</div>);
              const index = (rowIndex * 3) + colIndex;
              return <Position key={`position-${index}`} country={country} onFillPosition={handleFillPosition} filled={filled[index]} index={index}  />
        }))}
      </div>
      {!filled.reduce((previous, current) => previous && current, true) && (<div className="jigsaw-piece-wrapper">
      {[1,2,3,4,5,6,7,8,9].map(piece => {
        return <PuzzleTile key={`tile-${piece}`} filled={filled[piece - 1]} country={country} pieceIndex={piece} />
      })}
      </div>)}
      {filled.reduce((previous, current) => previous && current, true) && ((language =="en") ? (<div className="jigsaw-complete-wrapper">
        <Confetti />
        <h3>Congratulations, you completed the puzzle</h3>
        <div className="puzzle-button-wrapper">
          <button onClick={handleReset}>Play again</button>
          <button onClick={handleClose}>Go back to the common room</button>
        </div>
      </div>) : (<div className="jigsaw-complete-wrapper">
        <Confetti />
        <h3>完成です！おめでとうございます！</h3>
        <div className="puzzle-button-wrapper">
          <button onClick={handleReset}>もう一度<br/>トライ！</button>
          <button onClick={handleClose}>ラウンジに戻る</button>
        </div>
      </div>))}

    </div>
  </DndProvider>);
};

export default Puzzle;

interface PositionProps {
  index: number,
  filled: Boolean,
  onFillPosition,
  country: string,
}

const Position:React.FC<PositionProps> = ({index,filled, onFillPosition, country}) => {
  const [{isOver}, dropRef] = useDrop(() => ({
    accept: `puzzle-tile`,
    drop: (item: {tileIndex: number}, monitor) => {
      if(item.tileIndex === index) {
        onFillPosition(index);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    })
  }));
  const positionClass = classNames(
    'jigsaw-tile-position', 
    {
      hover: isOver,
      filled: filled
    }
  );

  return (
    <div ref={dropRef} className={positionClass}>
      {filled && <img src={`/puzzles/${country}/tile-${index + 1}.png`} />}
    </div>
  );
}

interface PuzzleTileProps {
  pieceIndex,
  filled,
  country
};

const PuzzleTile:React.FC<PuzzleTileProps> = ({pieceIndex, filled, country}) => {
  const [{isDragging}, dragRef, preview] = useDrag(() => ({
    type: `puzzle-tile`,
    item: {
      tileIndex: pieceIndex - 1,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));
  const top = useRef(Math.floor(Math.random() * 400) + 10);
  const right = useRef(Math.floor(Math.random() * 200) + 10);
  const rotate = useRef(Math.floor(Math.random() * 40) - 20);
  const zindex = useRef(Math.floor(Math.random() * 10) + 1);
  return (<>
      {!filled && (<>
        <DragPreviewImage connect={preview} src={`/puzzles/${country}/tile-${pieceIndex}.png`} />
        <div className="puzzle-tile"  style={{zIndex: zindex.current, top: top.current, right: right.current, transform: `rotate(${rotate.current}deg)`}}>
          <img ref={dragRef} src={`/puzzles/${country}/tile-${pieceIndex}.png`} style={{opacity: isDragging ? 0 : 1}} />
        </div>
      </>)}
    </>
    );
}