import React, { useCallback, useEffect, useState, useRef } from 'react';
import Webcam from 'react-webcam';

import './styles.scss';

interface DanceProps {
  hidePopUp,
  country: string,
  language: string,
  danceOverlay: boolean,
  audioPath: string,
  open: boolean,
};

const WebCamera:React.FC<DanceProps> = ({hidePopUp, country, language, danceOverlay, audioPath, open}) => {
  const [showDance, setShowDance] = useState(false);
  const [danceTextIndex, setDanceTextIndex] = useState(0);
  const timer = useRef(null);

  const danceText = {
    en: [
      'Dance!',
      'Dance!',
      'Let yourself go! ',
      'As much movement as you can commit to for your body is perfect.',
      'Seriously…..',
      'Peer-reviewed research insists that moving to rhythmic beats is good for general well-being and endorphin release.',
      'Are you loving it?',
      'Are you definitely dancing?',
      'Dance!',
    ],
    jp: [
      'ダンス！',
      'ダンス！',
      '自分を解放して！',
      'できる範囲で動いてみましょう',
      '真面目な話…',
      'リズムにのって体を動かすとエンドルフィンが放出されて幸福感が高まるらしい',
      'ノってるかい？',
      'ホントに踊ってる？',
      'ダンス！',
    ]
  }

  const toggleDance = useCallback(() => {
    let status = false;
    

    setShowDance(previous => {
      status = !previous;
      return !previous;
    });

    const interval = (status) ? 5000 : 2000;

    if(status) {
      setDanceTextIndex(previous => {
        return ((previous + 1) % danceText[language].length)
      });
    }
    setTimer(interval);

  }, [showDance, setShowDance, setDanceTextIndex]);

  const setTimer = (interval) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(toggleDance, interval, setDanceTextIndex, danceText);
  }


  useEffect(() => {
    if(danceOverlay) {
      setTimeout(toggleDance, 300);
    }
  }, [danceOverlay]);

  useEffect(() => {
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <div className="webcam-wrapper">
      {open && (
        <>
          <Webcam audio={false} />
          <div className="webcam-dance-wrapper">
            {showDance && (
              <p>
                {danceText[language][danceTextIndex]}
              </p>
            )}
          </div>
          {audioPath && <audio autoPlay={true} playsInline={true} controls={false} loop={true} src={`/audio/${audioPath}`} />}
        </>
      )}
    </div>
  );
};

export default WebCamera;