import React from 'react';
import './styles.scss';

interface CastProps {
  hidePopUp,
  language: string,
  country: string
};

const Cast:React.FC<CastProps> = ({hidePopUp, language, country}) => {

  const castList = ``

  return (
    <div className="cast-wrapper">
      <div className="cast-inner">
        {language === 'en' && (
        <>
          <p>Christopher Green's The Digital Home</p>
          <p>Lead Artist: Christopher Green</p>
          <div>
            <p>[UK]</p>
            <p>Producer: Caggy Kerlogue</p>
            <p>Producer: Linda Bloomfield</p>
            <p>Assistant Producer: Alife Heffer</p>
          </div>
          <div>
            <p>[Japan]</p>
            <p>Writer/Director: Naoki Sugawara</p>
            <p>Producer: Sachiko Ukegawa</p>
          </div>
          <div>
            <p>[UK Films]</p>
            <p>Care in a Crisis / First Aid Room, Avatars, Foyer, Control Room, The Managers Office, The Library</p>
            <p>By Napier Films</p>
          </div>

          <div>
            <p>Your Robotic Companion: The Future of Care / The Garden</p>
            <p>By CAMEYE</p>
          </div>

          <div>
            <p>How Care Feels / Residents Room</p>
            <p>By Rosie Litterick</p>
          </div>

          <div>
            <p>The Roadshow / The Car Park </p>
            <p>By Sean Lovell</p>
          </div>

          <div>
            <p>[Japanese Films]</p>
            <p>All Films By Shoji Toyama</p>
            <p>(except for The Library)</p>
          </div>

          <div>
            <p>[Digital Platform Development & Creation]</p>
            <p>By Marmelo Digital</p>
          </div>

          <div>
            <p>[Illustration]</p>
            <p>By Kevin House</p>
          </div>

          <p>Created in Collaboration with</p>

          <div>
            <p>[For The Home (UK)]</p>
            <p>Christopher Keeton-Green (CEO): Christopher Green</p>
            <p>Danny (Manager): Daniel Jacob</p>
            <p>Lucy (Care Worker): LJ Parkinson</p>
            <p>Umar (Care Worker): Umar Butt</p>
            <p>Leigh (Care Worker): Archie Backhouse</p>
            <p>Anita (Activities Co-Ordinator): Annie Siddons</p>
            <p>Jason (Activities Co-Ordinator): Jason York</p>
            <p>Patroyska (Cleaner): Pat Dynowska</p>
            <p>Dalal (Cleaner): Colette Dalal Tchantcho</p>
            <p>Ricky (Visitor): Richard Rycroft</p>
            <p>Gilly (Resident): Gillian Daniels</p>
            <p>Catherine (Resident): Catherine Herman</p>
            <p>Vicki (Human Resources): Alex Kelly</p>
            <p>Robotic Companion: Pepper</p>
            <p>Robotic Companion’s Companion: Stephen Potter & The University of Sheffield</p>
            <p>Academics</p>
          </div>

          <div>
            <p>For Glorius Age</p>
            <p>Ochuko (Resident)</p>
            <p>Daisy (Resident)</p>
            <p>Ron (Resident)</p>
            <p>Kurban (Resident)</p>
            <p>Rosaline (Resident)</p>
            <p>Shelia (Resident)</p>
            <p>Grace (Resident)</p>
          </div>

          <div>
            <p>[For The Home (Japan)]</p>
            <p>Naoto Kashihara (RCE/Manager): Naoki Sugawara</p>
            <p>Hideki Domoto (Care Worker): Shinnosuke Suzuki</p>
            <p>Fumio Kiyota (Care Worker): Arifumi Takeda</p>
            <p>Yuya Hama (Counselor): Hikaru Abe</p>
            <p>Yu Mizuhara (Nurse): Kei Sato</p>
          </div>

          <div>
            <p>For Saitama Gold Theatre</p>
            <p>Masae Kodama (Reskident): Kayo Ishikawa</p>
            <p>Tetsuzo Kobayashi (Resident): Hiroshi Kassai</p>
            <p>Minoru Yamagishi (Resident): Ryuichi Morishita</p>
            <p>Osamu Takamori (Part Time Care Worker): Masatake Takei</p>
            <p>Fumi Kondo (Resident): Miwako Ogushi</p>
            <p>Yoko Takashima (Resident): Ritsuko Tamura</p>
            <p>Kozo Yamakawa (Resident): Kiyoshi Takahashi</p>
            <p>Kumi Takagi (Yamakawa’s Daughter): Keiko Hayashida</p>
            <p>Harue Yamakawa (Yamakawa’s Wife/Resident): Natsue Hyakumoto</p>
            <p>Shozo Kaneyama (Neighbour/Resident): Yoichi Toyama</p>
            <p>Mitsuyo Obuchi (Resident)</p>
            <p>Tae Takizawa (Resident’s family)</p>
            <p>Gasho Kitazawa (Resident)</p>
          </div>

          <div>
            <p>Yamagishi’s Wife/Narration: Tamiko Morishita</p>
            <p>Kodama’s Sister in Childhood: Ikuno Takeda</p>
          </div>

          <div>
            <p>For Library films</p>
            <p>Risa Eto (Lecturer): Asa Ito</p>
            <p>Daijiro Toriyama (Lecturer): Daisuke Son</p>
          </div>

          <div>
            <p>[For Marmelo]</p>
            <p>Co-Founder: Luke Alexander</p>
            <p>Co-Founder: Abhinav Bajpai</p>
            <p>Creative Director: Kerry Joyce</p>
            <p>Junior Creative Technologist: Tatiana Disu</p>
            <p>Illustrator: Kevin House</p>
          </div>

          <div>
            <p>[For Napier Films]</p>
            <p>Director/Editor: Naomi Soneye-Thomas</p>
            <p>Production Coordinator: Liv Hansen (Liv Han-Sen)</p>
            <p>DP: Anthony Lucas (Ant-Ony Lu-Cas)</p>
            <p>DP: Matteo Bangrazi (Mat-Te-O Ban-Gra-Zi)</p>
            <p>Sound Recordist: Joeline Lindqvist (Jo-lean Link-Vist)</p>
            <p>Sound Recordist: Javier Carles (Ha-Vi-Air Car-Les)</p>
            <p>1st AC: Eduardo Fuica (Ed-Ward-O Fu-We-Ka)</p>
            <p>Production Designer: Ana Luna Lajeunesse (Ana Lu-Na Lie-Une-Ness)</p>
            <p>DIT / Cam Asst: Andrea Di Franco (An-Drey-A Di Fran-Co)</p>
            <p>Graphic Design, Logo animation, Production Assistant, Editor of Music Video: Hector Kuenzler-Byrt (Hect-Or Kinz-Ler)</p>
            <p>Production Assistant, Filmed Ricky Film and additional B Roll content: Holly Mcgratten (Holly Mc-Grat-Ten)</p>
            <p>Film / Edit – Tik Tok Videos: Mas Media, Editor: Samantha Scott and Videographer: Sammy Niugna (Sam-An-Tha Scot) & (Sammy New-Nee)</p>
            <p>Colourist: Emma Johnson (Em-Ma Jon-Son)</p>
            <p>Colourist: Michelle Cort (Mish-Ell Court)</p>
            <p>Sound Designer / Sound Mix: Liam Cameron (Li-Am Cam-A-Ron)</p>
            <p>Production Assistant: Tyler Chilon (Tie-Ler Kye-Lon)</p>
            <p>Editor/Production Assistant: Chinaza Onyechi (Chin-A-Za On-Yo-Key)</p>
            <p>Online recording assistant: Imogen Wyatt Corner</p>
            <p>Camera Hire: Direct Digital</p>
          </div>

          <div>
            <p>[For CHAMEYE]</p>
            <p>Ana De Matos</p>
          </div>

          <div>
            <p>[For Rosie Litterick]</p>
            <p>Director: Rosie Litterick</p>
            <p>DOP and Lighting: Tristan Hutchinson</p>
            <p>Art Department: Martha Howe</p>
            <p>Sound recordist/ mixer: Tom Blawat</p>
          </div>

          <div>
            <p>[For Japanese Films]</p>
            <p>Director: Shoji Toyama</p>
            <p>Camera & Edit: Shinsuke Kato</p>
            <p>Coordinator: Tomoya Takeda (bench)</p>
            <p>Lighting: Haruki Kamata</p>
            <p>Sound recordist: Michi Yamamoto</p>
            <p>Wardrobe & Costume Design for Kobayashi’s Film: Yuko Yamagishi</p>
            <p>Hair & Makeup: Tomoko Miyazaki</p>
            <p>Production Assistant: Ayumi Seki</p>
            <p>Production Support: Aya Comori (bench/TASCO)</p>
            <p>Production: Miki Tanaka, Takako Maeda, Tatsuya Takagi (Saitama Arts Theatre)</p>
            <p>In cooperation with:  Key Station, Hiroshi Ota (Key Station), Shinrin Kohan Reien (Sudo Sekizai Co., Ltd.), Namegawa Town, Peace Boat, Omiya Nishi Kanko, Komone Clinic Studio (Planer)</p>
            <p>[For Radio Exercises No.1]</p>
            <p>Copyrights: Japan Post Insurance Co., Ltd.</p>
            <p>Licensed by  </p>
            <p>NHK Service Centre, INC.</p>
          </div>

          <div>
            <p>[Subtitles] </p>
            <p>AURA Co., Ltd.</p>
          </div>

          <div>
            <p>[Interpreters]</p>
            <p>Tomoko Momiyama (Art Translators Collective), Nao Suzuki, Kanoko Tamura (Art Translators Collective) </p>
          </div>

          <div>
            <p>[For 89up: Press and Marketing]</p>
            <p>Editorial Director: Padraig Reidy</p>
            <p>Senior Account Executive: Skye Redman</p>
            <p>Junior PR Account Executive Lewis Redmond</p>
          </div>

          <div>
            <p>[For the Albany]</p>
            <p>Chief Executive/Artistic Director: Gavin Barlow</p>
            <p>Finance Director: Mary Nri</p>
            <p>Finance Manager: Mark Blay</p>
            <p>Executive Coordinator: Alison Hamilton</p>
            <p>Head of Operations & Production: Ben Stephen</p>
            <p>Deputy Production Manager: Michael Cunningham</p>
            <p>Head of Engagement: Kirsty Collander-Brown</p>
            <p>Garden Projects Coordinator: David Dandridge</p>
            <p>Associate Director: Vicki Amedume</p>
            <p>Programme Coordinator: Stacey O’Shea</p>
            <p>Head of Communications: Becca Pratt</p>
            <p>Marketing & Engagement Coordinator: Tayo Olowo-okere</p>
            <p>Marketing and Engagement Manager (Interim): Holly Thompson</p>
            <p>General Manager (Deptford Lounge): Annette Butler</p>
            <p>Duty Managers (Deptford Lounge): Ronan Morley , Jody DeSchutter</p>
          </div>

          <div>
            <p>[For Entelechy Arts]</p>
            <p>Artistic Director: Maddy Mills</p>
            <p>Creative Director: Rebecca Swift</p>
            <p>General Manager: Christine Lee</p>
            <p>Associate: David Slater</p>
            <p>Programmes Coordinator: Roxanna Kennedy</p>
            <p>Relationship & Access Coordinator: Jasmine Sparrow</p>
            <p>Administrator & Marketing Coordinator: Gaia Bini</p>
            <p>Meet Me Producer: Sophie Merriman</p>
            <p>Finance Officer: Silvana Pizzolo</p>
            <p>PR: Binita Walia</p>
          </div>

          <div>
            <p>[For Saitama Arts Theatre]</p>
            <p>President: Yoichi Kato</p>
            <p>General Director: Masashi Kagesawa</p>
            <p>Executive Producer: Hiroshi Watanabe</p>
            <p>Theatre Administration Department Manager: Takeaki Iwashina</p>
            <p>Technical Division Manager: Jun’ichi Yamada</p>
            <p>Production Division Manager: Yukio Nakamura</p>
            <p>Production: Miki Tanaka, Tatsuya Takagi</p>
            <p>PR & Marketing Manager: Norihisa Tsurugai</p>
            <p>PR & Marketing: Satoshi Matsui, Sayaka Hayashi</p>
          </div>
            
          <div>
            <p>[Co-produced & Presented by]</p>
            <p>The Japan Foundation, Christopher Green, The Albany, Entelechy Arts, Saitama Arts Theatre</p>
          </div>
        </>
        )}
        {language === 'jp' && (
          <>
            <p>『The Home オンライン版』</p>


            <p>原案・総合演出：クリストファー・グリーン</p>

            <div>
              <p>［英国版］</p>
              <p>作・演出：クリストファー・グリーン</p>
              <p>プロデューサー：カギー・カーローグ</p>
              <p>プロデューサー：リンダ・ブルームフィールド</p>
              <p>アシスタント・プロデューサー：アルフィー・へファー</p>
            </div>

            <div>
              <p>［日本版］</p>
              <p>作・演出：菅原直樹</p>
              <p>プロデューサー：請川幸子</p>
            </div>

            <div>
              <p>［デジタルプラットフォーム制作］ </p>
              <p>マルメロ・デジタル</p>
            </div>

            <div>
              <p>［イラストレーション］</p>
              <p>ケビン・ハウス </p>
            </div>

            <div>
              <p>［英国版 映像］</p>
              <p>『非常事態宣言下の介護』 </p>
              <p>〈医務室〉〈アバター〉〈ホワイエ〉〈コントロール室〉〈施設長の部屋〉〈読書室〉</p>
              <p>映像監督：ナピエール・フィルムズ</p>
            </div>

            <div>
              <p>『友達はロボット：介護の未来』〈庭〉</p>
              <p>映像監督：キャムアイ</p>
            </div>

            <div>
              <p>『介護される気持ち』〈入居者の部屋〉</p>
              <p>映像監督：ロージー・リッテリック</p>
            </div>

            <div>
              <p>『ロードショー』〈駐車場〉</p>
              <p>映像監督：ショーン・ロヴェル</p>
            </div>

            <div>
              <p>［出演］</p>
              <p>クリストファー・キートン＝グリーン（CEO）：クリストファー・グリーン</p>
              <p>ダニー（施設長）：ダニエル・ジェイコブ</p>
              <p>ルーシー（ケアワーカー）：LJ・パーキンソン</p>
              <p>ウマー（ケアワーカー）：ウマー・バット</p>
              <p>リー（ケアワーカー）：アーチー・バックハウス</p>
              <p>アニータ（アクティビティコーディネーター）：アニー・シドンズ</p>
              <p>ジェイソン（アクティビティコーディネーター）：ジェイソン・ヨーク</p>
              <p>パトロイスカ（清掃員）：パット・ダイノウスカ</p>
              <p>ダラル（清掃員）：コレット・ダラル・チャンチョ</p>
              <p>リッキー（訪問客）：リチャード・ライクロフト</p>
              <p>ジリー（入居者）：ジリアン・ダニエルズ</p>
              <p>キャサリン（入居者）：キャサリン・ハーマン</p>
              <p>ビッキー（人事担当）：アレックス・ケリー</p>
              <p>ともだちロボット：ペッパー</p>
              <p>ともだちロボットの友人：ステファン・ポッター＆シェフィールド大学研究チーム</p>
            </div>

            <div>
              <p>グロリアス・エイジ </p>
              <p>オチュコ（入居者）</p>
              <p>デイジー（入居者）</p>
              <p>ロン（入居者）</p>
              <p>クールバン（入居者）</p>
              <p>ロザリン（入居者）</p>
              <p>シェイラ（入居者）</p>
              <p>グレース（入居者）</p>
            </div>

            <div>
              <p>［ナピエール・フィルムズ］</p>
              <p>ディレクター／編集：ナオミ・ソナイ＝トーマス</p>
              <p>プロダクション・コーディネート：リヴ・ハンセン</p>
              <p>撮影監督：アンソニー・ルーカス、マッテオ・バングラジ</p>
              <p>音声収録：ジョリーン・リンクビスト、ハビエル・カーレス</p>
              <p>AC：エドワルド・フイーカ</p>
              <p>プロダクション・デザイナー：アナ・ルナ・リーウネス</p>
              <p>DIT／カメラアシスタント：アンドレア・ディ・フランコ</p>
              <p>グラフィックデザイン／ロゴアニメーション／プロダクション・アシスタント／ミュージックビデオ編集：ヘクター・キンズラー＝バイル</p>
              <p>プロダクション・アシスタント／撮影（リッキー／B-Roll）：ホリー・マクグラッテン</p>
              <p>撮影・編集（Tik Tok）：マス・メディア</p>
              <p>編集（Tik Tok）：サマンサ・スコット、サミー・ニューニ</p>
              <p>カラリスト：エマ・ジョンソン、ミシェル・コート</p>
              <p>サウンドデザイン／ミキシング：リアム・キャメロン</p>
              <p>プロダクション・アシスタント：タイラー・キーロン</p>
              <p>エディター／プロダクション・アシスタント：チナーザ・オンヨキー</p>
              <p>オンライン録画アシスタント：イメジン・ワット・コーナー</p>
              <p>機材協力：ダイレクト・デジタル</p>
            </div>

            <div>
              <p>［チャムアイ］</p>
              <p>アナ・デ・マトス</p>
            </div>

            <div>
              <p>［ロージー・リテリック］</p>
              <p>ディレクター：ロージー・リテリック</p>
              <p>撮影監督／照明：トリスタン・ハッチンソン</p>
              <p>アート部門：マーサ・ハウ</p>
              <p>音声収録／ミキシング：トム・ブラワット</p>
            </div>

            <div>
              <p>［89アップ］</p>
              <p>編集ディレクター：ポードレック・リーディ</p>
              <p>シニア・アカウント・エグゼクティブ：スカイ・レッドマン</p>
              <p>ジュニアRPアカウント・エグゼクティブ：ルイス・レッドモンド</p>
            </div>

            <div>
              <p>［日本版 映像］</p>
              <p>映像監督：遠山昇司</p>
            </div>

            <div>
              <p>［出演］</p>
              <p>柏原直人（RCE・施設長）：菅原直樹</p>
              <p>堂本秀樹（ケアワーカー）：鈴木真之介</p>
              <p>清田文夫（ケアワーカー）：武田有史</p>
              <p>浜 勇也（生活相談員）：阿部 輝</p>
              <p>水原 悠（看護師）：佐藤 蛍</p>
            </div>

            <div>
              <p>さいたまゴールド・シアター</p>
              <p>児玉雅恵（入居者）：石川佳代</p>
              <p>小林徹三（入居者）：葛西 弘</p>
              <p>山岸 実（入居者）：森下竜一</p>
              <p>高森 修（非常勤職員）：竹居正武</p>
              <p>近藤フミ（入居者）：大串三和子</p>
              <p>高島葉子（入居者）：田村律子</p>
              <p>山川耕三（入居者）：髙橋 清</p>
              <p>高木久美（山川の娘）：林田惠子</p>
              <p>山川春江（山川の妻）：百元夏繒</p>
              <p>金山正三（地域住民・入居者）：遠山陽一</p>
              <p>小渕光世（入居者）</p>
              <p>滝澤多江（入居者の家族）</p>
              <p>北澤雅章（入居者）</p>
            </div>

            <div>
              <p>山岸の妻（ナレーション）：森下田美子</p>
              <p>児玉の姉（幼少時代）：武田郁乃</p>
            </div>

            <div>
              <p>江藤理紗（講師）：伊藤亜紗（美学者・東京工業大学 教授）</p>
              <p>鳥山大二郎（講師）：孫大輔（医師・鳥取大学医学部）</p>
            </div>

            <div>
              <p>［スタッフ］</p>
              <p>撮影・編集：加藤信介</p>
              <p>映像プロデュース：武田知也（ベンチ）</p>
              <p>照明：鎌田春樹</p>
              <p>音声収録：山本未知</p>
              <p>衣裳管理・〈小林の部屋〉衣裳デザイン：山岸祐子</p>
              <p>ヘアメイク：宮﨑智子</p>
              <p>制作アシスタント：関あゆみ</p>
              <p>制作補佐：小森あや（ベンチ／TASKO）</p>
              <p>制作：田中美樹、前田貴子、高木達也（彩の国さいたま芸術劇場）</p>
            </div>

            <div>
              <p>撮影協力：キーステーション、太田浩史（一般社団法人日本福祉環境整備機構キーステーション）、森林湖畔霊苑（須藤石材株式会社）、滑川町、国際交流NGOピースボート、大宮西観光株式会社、小茂根クリニックスタジオ（株式会社プラネアール）</p>
            </div>

            <div>
              <p>［ラジオ体操第一 音楽使用許諾］</p>
              <p>著作権：株式会社かんぽ生命保険</p>
              <p>音源提供：NHKサービスセンター</p>
            </div>

            <div>
              <p>［字幕制作］</p>
              <p>株式会社アウラ</p>
            </div>

            <div>
              <p>［マルメロ・デジタル］</p>
              <p>共同代表：ルーク・アレクサンダー</p>
              <p>共同代表：アブヒナブ・バジュパイ</p>
              <p>クリエイティブ・ディレクター：ケリー・ジョイス</p>
              <p>ジュニア・クリエイティブ・テクノロジスト：タチアナ・ディスー</p>
              <p>イラストレイター：ケビン・ハウス</p>
            </div>

            <div>
              <p>［通訳］</p>
              <p>樅山智子（Art Translators Collective）、鈴木なお、田村かのこ（Art Translators Collective）</p>
            </div>

            <div>
              <p>［オールバニー劇場］</p>
              <p>チーフ・エグゼクティブ／芸術監督：ギャビン・バーロウ</p>
              <p>財務ディレクター：メアリー・リー</p>
              <p>財務マネージャー：マーク・バレイ</p>
              <p>エグゼクティブ・コーディネーター：アリソン・ハミルトン</p>
              <p>運営＆事業チーフ：ベン・スティーブン</p>
              <p>事業副チーフ：マイケル・カニンガム</p>
              <p>エンゲージメント・チーフ：カースティ・コランダー＝ブラウン</p>
              <p>ガーデンプロジェクト・コーディネーター：デービッド・ダンドリッジ</p>
              <p>アソシエイト・ディレクター：ビッキー・アメドゥーメ</p>
              <p>プログラム・コーディネーター：ステイシー・オーシェイ</p>
              <p>広報チーフ：ベッカ・プラット</p>
              <p>マーケティング＆エンゲージメント・コーディネーター：タヨ・オロウウォ＝オケラ</p>
              <p>マーケティング＆エンゲージメント・臨時マネージャー：ホリー・トンプソン</p>
              <p>ジェネラル・マネージャー（デプトフォード・ラウンジ）：アネット・バトラー</p>
              <p>デューティー・マネージャー（デプトフォード・ラウンジ）：ローナン・モーレイ、ジョディ・デシュッター</p>
            </div>

            <div>
              <p>［エンテレキー・アーツ］</p>
              <p>芸術監督：マディー・ミルズ</p>
              <p>クリエイティブ・ディレクター：レベッカ・スウィフト</p>
              <p>ジェネラル・マネージャー：クリスティーン・リー</p>
              <p>アソシエイト：デービッド・スレイター</p>
              <p>プログラム・コーディネーター：ロクサーナ・ケネディ</p>
              <p>地域連携＆アクセス・コーディネーター：ジャスミン・スパロウ</p>
              <p>アドミニストレーション＆マーケティング・コーディネーター：ガイア・ビニ</p>
              <p>ミート・ミー・プロデューサー：ソフィー・メリマン</p>
              <p>財務担当：シルバーナ・ピゾッロ</p>
              <p>広報：ビニータ・ワリア</p>
            </div>

            <div>
              <p>［彩の国さいたま芸術劇場］</p>
              <p>理事長：加藤容一</p>
              <p>専務理事：影沢政司</p>
              <p>事業部長：渡辺 弘</p>
              <p>劇場部長：岩品武顕</p>
              <p>制作技術課長：山田潤一</p>
              <p>企画制作課長：中村行雄</p>
              <p>営業広報課長：鶴貝典久</p>
              <p>営業広報：松井哲、林さやか</p>
            </div>

            <div>
              <p>［主催・共同制作］</p>
              <p>独立行政法人国際交流基金、クリストファー・グリーン、オールバニー劇場、エンテレキー・アーツ、公益財団法人埼玉県芸術文化振興財団（彩の国さいたま芸術劇場）</p>
            </div>

          </>
        )}
        <img src="/prod-logos.jpg" />
      </div>
    </div>
  );
};

export default Cast;