import React, {useState, useCallback} from 'react';
import classNames from 'classnames';
import Frame from '../../Frame';
import './styles.scss'

interface UrineProps {
  hidePopUp,
  country: string,
  language: string,
};

const Urine:React.FC<UrineProps> = ({hidePopUp, country, language}) => {
  const [filled, setFilled] = useState(false);

  const bottleClasses = classNames(
    'urine-test-bottle-fill',
    {
      fill: filled
    }
  );

  const handleButtonPress = useCallback(() => {
    setFilled(true);
  }, [setFilled]);

  const handleClosePress = useCallback(() => {
    hidePopUp();
    setFilled(false);
  }, [setFilled]);

  return (<div className="urine-test-wrapper">
    <Frame country={country}>
    <div className="urine-test-inner">
      <div className="urine-test-intro">
        {language === 'en' && (
          <>
            <p>The Home invites you to take a daily urine test.</p>
            <p>Please use a The Home branded cup and leave it on the shelf in your bathroom for your care worker to collect.</p>
            <p>Your named Activity CoOrdinator will discuss any causes for concerns.  They may suggest interventions ranging from hydration to supplements which may result in referrals to the Top Floor. </p>
            {!filled && 
              <button onClick={handleButtonPress}>Provide your sample</button>}
            {filled && <button onClick={handleClosePress}>Close</button>}
          </>
        )}
        {language === 'jp' && (
          <>
            <p>The Homeでは毎日の尿検査をお勧めしています。The Homeのロゴ入り紙コップに尿を採取して居室の棚に置いてください。ケアワーカーが回収にまいります。お悩みがあればあなたのご指名のアクティビティコーディネーターがご相談を受け付けます。水分補給からサプリメントまで様々な介入補助をご提案します。</p>
            {!filled && 
              <button onClick={handleButtonPress}>尿を提出</button>}
            {filled && <button onClick={handleClosePress}>Close</button>}
          </>
        )}        
      </div>
      <div class="urine-test-bottle-wrapper">
        <div className="urine-test-bottle">
          <img src={`/urine/bottle-${country}.png`} />
          <div className={bottleClasses}>
            {filled && <img src={`/urine/fill-bottom.png`} className="urine-test-bottle-bottom" />}
            <img src={`/urine/bottle-label-${country}.png`} className="urine-test-bottle-label" />
          </div>
        </div>
      </div>
    </div>
  </Frame>
  </div>);
};

export default Urine;