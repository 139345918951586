import React, {useState, useCallback} from 'react';
import Frame from '../../Frame';
import './styles.scss';

interface DementiaQuizProps {
  hidePopUp,
  country: string,
  language: string,
};

const DementiaQuiz:React.FC<DementiaQuizProps> = ({hidePopUp, country, language}) => {
  const [quizStep, setQuizStep] = useState(0);
  const [answer, setAnswer] = useState(null);
  const questions = {
    en: [
      {
        questionText: 'You wake up and find yourself lying in the sofa in a room you haven’t been, with the people you’ve never seen. What do you think your reaction will be like?  You will:',
        options: ['Leave the room', 'Stay on the sofa', 'Talk to anyone in the room', 'Get shocked and shout or shed tears for fears'],
      },
    ],
    jp: [
      {
        questionText: '目覚めると、あなたは見覚えのない部屋のソファーに座っていて、周りには見覚えのない人々がいます。どのような行動をとりますか',
        options: ['とりあえず部屋を出る', 'ソファーに座り続ける', 'だれかれ構わず声をかける', '驚いて大きな声を出す、怖くなって涙を流す']
      },
    ]
  };

  const answers = {
    en: ['Wandering', 'Withdrawal', 'Repeating the same questions', 'Emotional instability'],
    jp: ['徘徊','閉じこもり', '何度も同じことを聞く', '喜怒哀楽が不安定']
  };

  const handleUpdateAnswer = useCallback(index => {
    setAnswer(index);
  }, [setAnswer])

  const handleNextQuestion = useCallback(() => {
    setQuizStep(previous => previous + 1);
  }, [setQuizStep]);

  const handlePreviousQuestion = useCallback(() => {
    setQuizStep(previous => previous - 1);
  }, [setQuizStep]);

  const handleReset = useCallback(() => {
    setQuizStep(0);
  }, [setQuizStep]);

  return (
    <div className="dementia-wrapper">
      <Frame country={country}>
        <div className="dementia-inner">
          {questions[language].length > 0 && quizStep < questions[language].length && (
            <>
              <div className="dementia-question-wrapper">
                <p>{questions[language][quizStep].questionText}</p>
                <ul>
                  {questions[language][quizStep].options.map((option, index) => {
                    return <li key={`${quizStep}-${index}`}><label><input onClick={() => handleUpdateAnswer(index)} name="option" type="radio" />{option}</label></li>;
                  })}
                </ul>
                <div className="dementia-button-wrapper">
                  {quizStep > 0  && <button onClick={handlePreviousQuestion} >Previous</button>}
                  {quizStep < (questions[language].length - 1) && <button onClick={handleNextQuestion}>Next</button>}
                  {answer !== null && quizStep === (questions[language].length - 1) && <button onClick={handleNextQuestion}>{language === 'en' ? 'Continue' : '結果をみる'}</button>}
                </div>
              </div>
            </>
          )}
          {questions[language].length > 0 && quizStep >= questions[country].length && (
            <div className="dementia-result">
              <p>{language === 'jp' ? 'あなたの認知症タイプは：' : 'Your dementia type is:'}</p>
              <p className="dementia-final-value">
                {answers[language][answer]}
              </p>
              <p>
                {language === 'jp' && 'もしかしたら、認知症になったあなたはこういった行動をとって、周囲の人々を驚かせたり、困らせたりするかもしれません。しかし、それらの行動に至るまでの心理は極めて人間的なものです。'}
                {language === 'en' && 'In the future, you might surprise the people close to you with such behaviours, but you have all good reasons to do so. Those actions are genuinely human.'}
              </p>
              <button onClick={handleReset}>{language === 'en' ? 'Start over' : 'もう一度トライ'}</button>
              <button onClick={hidePopUp}>{language === 'en' ? 'Finish' : '終了する'}</button>
            </div>
          )}
        </div>
      </Frame>
    </div>
  );
};

export default DementiaQuiz;