import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import './styles.scss';

interface ImagePopupProps {
  params: {
    url?: string,
    carousel?: [string]
  },
  country: string
};

const ImagePopup:React.FC<ImagePopupProps> = ({params, country}) => {

  const baseURL = "https://the-home.s3.eu-west-2.amazonaws.com/";
  const imageURL = baseURL + "images/";
  
  if(params.carousel) {

    const images = params.carousel.map((e,i) => {
      return (
        <img src={imageURL+country+"/"+e} key={i} />
      );
    });

    return (
      <Carousel showStatus={false} showThumbs={false} showIndicators={false} showArrows={false} swipeable={true}>
        { images }
      </Carousel>   
    );


  } else {
    
    return (
      <img src={imageURL + country + "/" + params.url} />
    );
    
  }


}

export default ImagePopup;
