import React, {useCallback, useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas'
import Frame from '../../Frame';
import './styles.scss';

interface SignatureProps {
  hidePopUp,
  country: string,
  language: string,
};

const Signature:React.FC<SignatureProps> = ({hidePopUp, country, language}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signatureData, setSignatureData] = useState(null);
  const windowSize: Size = useWindowSize();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
  }, []);

  const updatePage = useCallback((direction) => {
    setPageNumber(previous => {
      const current = previous;
      const newPage = current + Math.sign(direction);
      if(newPage > 0 && newPage <= numPages) {
        return newPage;
      } else {
        return previous;
      }
    })
  }, [setPageNumber, numPages]);

  const updateSignatureData = useCallback((e) => {
    setSignatureData(e.target.toDataURL());
  }, [setSignatureData]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, [setNumPages]);

  return (
    <div className="signature-wrapper">
      <div className="signature-document-wrapper">
        <div className="signature-document-button-wrapper">
          {pageNumber > 1 && <button onClick={() => updatePage(-1)}>Previous</button>}
          {pageNumber < numPages  && <button onClick={() => updatePage(1)}>Next</button>}
        </div>
        <Document
          file="/insurance_en.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={windowSize.width/4} pageNumber={pageNumber} />
        </Document>
        {signatureData && pageNumber === numPages && <img className="signature-final" src={signatureData} />}
      </div>
      <div className="signature-form">
        <Frame country={country}>
        <p>Add your signature below</p>
        <SignatureCanvas onEnd={updateSignatureData} penColor='green' canvasProps={{width: windowSize.width/4, height: windowSize.width/6, className: 'sigCanvas'}} />
        
        </Frame>
      </div>
    </div>
    );  
};

export default Signature;

interface Size {
  width: number | undefined;
  height: number | undefined;
}

function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}