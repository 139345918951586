import React from 'react';

import './styles.scss';

interface TextPopupProps {
  params: {
    text: string
  }
};

const TextPopup:React.FC<TextPopupProps> = ({params: {text: string}}) => {
  return (<p>Text pop up</p>);
}

export default TextPopup;
