import React from 'react';

import Questionnaire from '../../Minigames/Questionnaire';
import Puzzle from '../../Minigames/Puzzle';
import WordCloud from '../../Minigames/WordCloud';
import Calculator from '../../Minigames/Calculator';
import FruitMachine from '../../Minigames/FruitMachine';
import DementiaQuiz from '../../Minigames/DementiaQuiz';
import WebCamera from '../../Minigames/WebCamera';
import Urine from '../../Minigames/Urine';
import Cast from '../../Minigames/Cast';

import './styles.scss';
import Signature from '../../Minigames/Signature';

interface MinigamePopupProps {
  params: {
    type?: string
    url?: string,
    srt?: {
      jp_transcription?: string,
      jp_translation?: string
    }
    text?: string
    danceOverlay?: boolean,
    audioPath: string,
  },
  hidePopUp,
  language: string,
  country: string,
  open: boolean,
};

const MinigamePopup:React.FC<MinigamePopupProps> = props => {
  const {country, params, hidePopUp, language, open} = props;
  switch(params.type) {
    case 'questionnaire': 
      return <Questionnaire hidePopUp={hidePopUp} country={country} />;
    case 'jigsaw':
      return <Puzzle hidePopUp={hidePopUp} country={country} language={language} />;
    case 'wordcloud':
      return <WordCloud hidePopUp={hidePopUp} country={country} language={language} />;
    case 'calculator':
      return <Calculator hidePopUp={hidePopUp} language={language} country={country} />;
    case 'vending_machine':
        return <FruitMachine hidePopUp={hidePopUp} language={language} country={country} />;
    case 'dementia_test':
      return <DementiaQuiz hidePopUp={hidePopUp} language={language} country={country} />;
    case 'webcam':
      return <WebCamera open={open} audioPath={params.audioPath} hidePopUp={hidePopUp} language={language} country={country} danceOverlay={params.danceOverlay} />
    case 'urine': 
      return <Urine country={country} language={language} hidePopUp={hidePopUp} />
    case 'cast':
      return <Cast country={country} language={language} hidePopUp={hidePopUp} />
    case 'signature':
      return <Signature country={country} language={language} hidePopUp={hidePopUp} />
    default: 
      return (<p>Minigame pop up</p>);
  }
}

export default MinigamePopup;
