import React, { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import './styles.scss';

interface FruitMachineProps {
  hidePopUp,
  country: string,
  language: string
}

const FruitMachine:React.FC<FruitMachineProps> = ({language, country, hidePopUp}) => {
  const tileCount = 12;
  const tileHeight = 8;
  const tileOffset = 3.5;
  const [reel1, setReel1] = useState(Math.floor(Math.random() * tileCount));
  const [reel2, setReel2] = useState(Math.floor(Math.random() * tileCount));
  const [reel3, setReel3] = useState(Math.floor(Math.random() * tileCount));
  const [first, setFirst] = useState(true);
  const [hold, setHold] = useState([false, false, false]);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const setReelValues = useCallback(() => {
    const win = Math.floor(Math.random() * 10);
    const anyHeld = hold.reduce((previous, current) => previous || current, false);
    if(win < 7 || anyHeld || first) {
      if(!hold[0]) {
        setReel1(current => current + (Math.floor(Math.random() * tileCount)) + (2 * tileCount * tileHeight));
      }
      if(!hold[1]) {
        setReel2(current => current + (Math.floor(Math.random() * tileCount)) + (4 * tileCount * tileHeight));
      }
      if(!hold[2]) {
        setReel3(current => current + (Math.floor(Math.random() * tileCount)) + (6 * tileCount * tileHeight));
      }
      if(first) {
        setFirst(false);
      }
    } else {
      const winningItem = Math.floor(Math.random() * tileCount);
      setReel1(current => current + tileCount - (current % tileCount) -1 + winningItem + (2 * tileCount * tileHeight));
      setReel2(current => current + tileCount - (current % tileCount) -1 + winningItem + (4 * tileCount * tileHeight));
      setReel3(current => current + tileCount - (current % tileCount) -1 + winningItem + (6 * tileCount * tileHeight));
    }
    setButtonsEnabled(false);
    setTimeout(() => {
      setButtonsEnabled(true);
    }, 3000);
  }, [setReel1, setReel2, setReel3, hold, setButtonsEnabled, setFirst, first]);

  const toggleHoldReel = useCallback((index) => {
    if(index < hold.length) {
      setHold(current => {
        const newHold = [...current];
        newHold[index] = !newHold[index];
        return newHold;
      })
    }
  }, [hold, setHold]);

  const unholdText = language=="en" ? "Unhold" : "GO";
  const holdText = language=="en" ? "Hold" : "STOP";

  return (<div className="fruit-machine-wrapper">
    <div className="fruit-machine-inner">
      {country=="jp" 
        ? (language=="jp" 
          ? <p>私たち〈あおぞら〉のダイニングでは、入居者様が好きな料理を選ぶことができる、セレクト食をご用意しています。ゲームを楽しみながらヘルシーな食事について学びましょう。<br /><br />3つのヘルシーチョイスをそろえると、とっておきのご褒美をもらえます！</p>
          : <p>Here at Aozora, we serve a variety of food and the choices are yours to make. This fun game helps you make the best decisions right here in this dining room.<br /><br /> Press stick and keep spinning until you get three healthy choices for a prize!</p>)
        : (language=="en"
          ? <p>Here at The Home, you are in control. All your healthy choices are yours to make, but this fun game helps you make the very best decisions right here in the dining room.<br /><br />Press stick to keep the healthy choices. Keep spinning until you get three healthy choices for a prize!</p>          
          : <p>The Homeでは、健康のためにどのような食事を選ぶかはあなた次第。でもここで、ゲームを楽しみながらヘルシーな選択をするお手伝いをします！<br /><br /> マシンを回転させて3つのヘルシーチョイスをそろえると、とっておきのご褒美をもらえます！</p>)
        }
        
      <div className="fruit-machine">
        <div className="fruit-machine-reel-wrapper">
          <div style={{backgroundPositionY: `${(reel1 * tileHeight) + tileOffset}vw`}} className="fruit-machine-reel"/>
          <button className="fruit-machine-hold-button" disabled={!buttonsEnabled}  onClick={() => toggleHoldReel(0)}>{`${hold[0] ? unholdText : holdText}`}</button>    
        </div>
        <div className="fruit-machine-reel-wrapper">
          <div style={{backgroundPositionY: `${(reel2 * tileHeight) + tileOffset}vw`}} className="fruit-machine-reel"/>
          <button className="fruit-machine-hold-button" disabled={!buttonsEnabled}  onClick={() => toggleHoldReel(1)}>{`${hold[1] ? unholdText : holdText}`}</button>    
        </div>
        <div className="fruit-machine-reel-wrapper">
          <div style={{backgroundPositionY: `${(reel3 * tileHeight) + tileOffset}vw`}} className="fruit-machine-reel"/>
          <button className="fruit-machine-hold-button" disabled={!buttonsEnabled} onClick={() => toggleHoldReel(2)}>{`${hold[2] ? unholdText : holdText}`}</button>    
        </div>
      </div>
      <button className="fruitmachine-lever-button" onClick={setReelValues} disabled={!buttonsEnabled}>{language=="en" ? "Spin" : "START"}</button>
      <div className="fruitmachine-result">
        {buttonsEnabled && (reel1 % tileCount) === (reel2 % tileCount) && (reel1 % tileCount) === (reel3 % tileCount) && (reel1 % 2 === 1) && (
          <>
            <Confetti />
            <p>{language=="en" ? "You are rewarded with the dopamine release of knowing you make the right choices for yourself!" : "ご褒美はドーパミンです！ドーパミン、たくさん出たはず！" } </p>
          </>
        ) }
      </div>
    </div>
  </div>);
};

export default FruitMachine;