import React, {useCallback, useState} from 'react';
import AnimatedNumber from "animated-number-react";
import Frame from '../../Frame';

import './styles.scss';

interface CalculatorProps {
  hidePopUp,
  country: string,
  language: string,
};

const Calculator:React.FC<CalculatorProps> = ({hidePopUp, country, language}) => {
  const [quizStep, setQuizStep] = useState(0);
  const [mockValue, setMockValue] = useState(0);
  const questions = {
    en: [
      {
        questionText: 'Are you?',
        options: ['Under 40', 'Under 55', 'Under 70', 'Under 82', 'Under 99', 'Prefer not to say']
      },
      {
        questionText: 'Home owner?',
        options: ['No', 'Yes - with mortgage', 'Yes - own outright', 'Prefer not to say']
      },
      {
        questionText: 'Income?',
        options: ['Under £34,500', 'Under £71,200', 'Under £110,700', 'Prefer not to say']
      },
      {
        questionText: 'Attitude towards comfort?',
        options: ['Water', 'Lager', 'Wine', 'Champagne']
      },
    ],
    jp: [
      {
        questionText: 'Are you?',
        options: ['Under 40', 'Under 55', 'Under 70', 'Under 82', 'Under 99', 'Prefer not to say']
      },
      {
        questionText: 'Home owner?',
        options: ['No', 'Yes - with mortgage', 'Yes - own outright', 'Prefer not to say']
      },
      {
        questionText: 'Income?',
        options: ['Under £34,500', 'Under £71,200', 'Under £110,700', 'Prefer not to say']
      },
      {
        questionText: 'Attitude towards comfort?',
        options: ['Water', 'Lager', 'Wine', 'Champagne']
      },
    ]
  };

  const handleNextQuestion = useCallback(() => {
    setQuizStep(previous => previous + 1);
    setMockValue(Math.floor(Math.random() * 100));
  }, [setQuizStep, setMockValue]);

  const handlePreviousQuestion = useCallback(() => {
    setQuizStep(previous => previous - 1);
    setMockValue(Math.floor(Math.random() * 100));
  }, [setQuizStep, setMockValue]);

  const handleReset = useCallback(() => {
    setQuizStep(0);
    setMockValue(0);
  }, [setQuizStep, setMockValue]);



  return (<div className="calculator-wrapper">
    <Frame country={country}>
      <div className="calculator-inner">
        {questions[language].length > 0 && quizStep < questions[language].length && (
          <>
            <div className="calculator-question-wrapper">
              <p>{questions[language][quizStep].questionText}</p>
              <ul>
                {questions[language][quizStep].options.map((option, index) => {
                  return <li key={`${quizStep}-${index}`}><label><input name="option" type="radio" />{option}</label></li>;
                })}
              </ul>
              <div className="calculator-button-wrapper">
                {quizStep > 0  && <button onClick={handlePreviousQuestion} >Previous</button>}
                {quizStep < (questions[language].length - 1) && <button onClick={handleNextQuestion} >Next</button>}
                {quizStep === (questions[language].length - 1) && <button onClick={handleNextQuestion} >Calculate the cost of my care</button>}
              </div>
            </div>
            <div className="calculator-numbers">
              <div className="calculator-mockup-wrapper">
                <div className="calculator-mockup-text"><AnimatedNumber value={mockValue} formatValue={value => value.toFixed()} />p</div>
                <div className="calculator-mockup-keypad">
                  <img src="/calculator-keypad.png" />
                </div>
              </div>
            </div>
          </>
        )}
        {questions[language].length > 0 && quizStep >= questions[language].length && (
          <div className="calculator-result">
            <p>Our calculator indicates that we can offer you care from only</p>
            <p className="calculator-final-value"><AnimatedNumber value={45} formatValue={value => value.toFixed()} />p a day.</p>
            <p>Over by the filing cabinet you can sign the agreement to commit to the absolutely no-obligation sign-up form.  It’s easier than a walk in the park! </p>
            <button onClick={handleReset}>Start over</button>
            <button onClick={hidePopUp}>Finish</button>
          </div>
        )}
      </div>
    </Frame>
  </div>);
};

export default Calculator;