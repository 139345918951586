import React from 'react';
import './styles.scss';

interface FrameProps {
  country: string,
  children
};

const Frame:React.FC<FrameProps> = ({country, children}) => {
  return (
    <div className="frame-wrapper">
      <div className="frame-top-wrapper">
        <div className="frame-top-left frame-corner"><img src={`/frame/${country}/tl.png`} /></div>
        <div className="frame-top"><img src={`/frame/${country}/logo.png`} /></div>
        <div className="frame-top-right frame-corner"><img src={`/frame/${country}/tr.png`} /></div>
      </div>
      <div className="frame-mid-wrapper">
        <div className="frame-mid-left"></div>
        <div className="frame-content">{children}</div>
        <div className="frame-mid-right"></div>
      </div>
      <div className="frame-bottom-wrapper">
        <div className="frame-bottom-left frame-corner"><img src={`/frame/${country}/bl.png`} /></div>
        <div className="frame-bottom"></div>
        <div className="frame-bottom-right frame-corner"><img src={`/frame/${country}/br.png`} /></div>
      </div>
    </div>
  );
};

export default Frame;