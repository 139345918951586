import React, {useRef, useState, useCallback} from 'react';
import classNames from 'classnames';
import Frame from '../../Frame';

import './styles.scss';

interface WordCloudProps {
  hidePopUp,
  country: string,
  language: string
};

const WordCloud:React.FC<WordCloudProps> = ({country, language, hidePopUp}) => {
  const words = useRef(['care','vulnerable','love','medicine','paliative','quality','luxury','award-winning','high end','top notch','active','fulfilled','new start','person-centred','fairness','respect','equality','dignity','autonomy','family','hope','joy','community','passion','fun','human rights','elders','wisdom','cleanliness','designer','jargon-free','private insurance','innovation','愛','人柄','安心','平穏','誠実','クリーン','オープン','ゆとり','資格','専門性','フェア','人間性','高品質','低価格','安全','健康','思いやり','人手','管理','楽しみ','個室','食事','プライバシー','売店','快適さ','家の近く','敷居が低い','アクティビティ','行事','リハビリ','信頼','自由','尊重','尊厳','平等','自立','家庭','希望','喜び','夢','コミュニティ','学び','人権','清潔','看取り']);
  const [userWord, setUserWord] = useState('');
  const [wordWrong, setWordWrong] = useState(false);
  const [showWordCloud, setShowWordCloud] = useState(false);
  const [wordCloudIndex, setWordCloudIndex] = useState(1);

  const handleWordChange = useCallback((e) => {
    setUserWord(e.target.value);
    setWordWrong(false);
  }, [setUserWord, setWordWrong]);

  const handleWordCloudSubmit = useCallback(() => {
    if (words.current.includes(userWord.toLowerCase())) {
      setWordWrong(false);
      setShowWordCloud(true);
      setWordCloudIndex(Math.floor(Math.random() * 3 + 1));
    } else {
      setWordWrong(true);
      setShowWordCloud(false);
    }
  }, [setWordWrong, setShowWordCloud, userWord, setWordCloudIndex]);

  const inputClasses = classNames(
    'wordcloud-input',
    {error: wordWrong}
  );

  const handleReset = useCallback(() => {
    setShowWordCloud(false);
    setWordWrong(false);
    setUserWord('');
  }, [setShowWordCloud, setWordWrong, setUserWord] )

  return (<div className="wordcloud-wrapper">
    <Frame country={country}>
      {!showWordCloud && (
      <div className="wordcloud-form-wrapper">
        {language=="jp" 
        ?  <p>〈あおぞら〉に入居したらどのような暮らしが実現すると思いますか？<br /><br />あなたが思い描く生活や期待する介護を表す言葉を入力して〈あおぞら〉のワードクラウドに参加しましょう！</p>
        : <p>Please enter a word that you feel represents the experience of being a resident at the Home.  We'll then add it to our word cloud.</p>
        }
        <p>{language=="jp" ? "ご注意ください：〈あおぞら〉独自のポジティブな介護ポリシーに沿わない言葉は削除させていただきます。" : " (Friendly note: The Home reserves the right to remove any word that is not in-line with its positive attitude to care)" }</p>        
        <p><input className={inputClasses} type="text" value={userWord} onChange={handleWordChange} /></p>
        <button onClick={handleWordCloudSubmit}>Enter</button>
      </div> )}
      {showWordCloud && (
      <div className="wordcloud-cloud-wrapper">
        <img src={`/wordclouds/${language}/wordcloud-${wordCloudIndex}.png`} />
        <button onClick={handleReset}>{language=="jp" ? "ワードを追加" : "Add another word"}</button>
      </div> )}
    </Frame>
  </div>);
}

export default WordCloud;