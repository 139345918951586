import React from 'react';
import { Link } from "gatsby"
import {useState} from 'react';
import ImagePopup from '../Image';
import MinigamePopup from '../Minigame';
import TextPopup from '../Text';
import VideoPopup from '../Video';

import "./styles.scss"


interface PopupProps {
  visible: Boolean,
  type: String,
  params: {
    url?: string,
    srt?: {
      jp_transcription?: string,
      jp_translation?: string
    },
    type?: string,
    text?: string,
    audioPath?: string,
  },
  hidePopup,
  language: string,
  country: string
};


const Popup:React.FC<PopupProps> = ({
  visible,
  type,
  params,
  hidePopup,
  language,
  country,
}) => {
  const handlePopupClose = (e:MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    hidePopup();
  };

  return (
    <div className={`popup-wrapper${visible ? ' active' : ''}`}>
      <div className="popup-close-wrapper"><a onClick={(e) => handlePopupClose} className="popup-close-button" href="#">Close</a></div>      
      <div className={"popup-inner " + "type-"+type}>      
        {type === 'video_pop_up' && <VideoPopup params={params} open={visible} country={country} language={language}/>}
        {type === 'image_pop_up' && <ImagePopup params={params} country={country} />}
        {type === 'text_pop_up' && <TextPopup params={params} />}
        {type === 'minigame_pop_up' && <MinigamePopup open={visible} language={language} country={country} params={params} hidePopUp={hidePopup} />}
      </div>
    </div>
  )
};

export default Popup;